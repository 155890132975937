export function sanitizeSlug(slug: string): string {
  if (!slug) throw new RangeError('The slug is required')

  return (
    slug
      .trim()
      .toLowerCase()
      // normalize and remove all combining diacritics (accents)
      // https://symbl.cc/en/unicode-table/#combining-diacritical-marks
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  )
}
